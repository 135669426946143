var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticStyle:{"max-width":"600px"},attrs:{"no-body":""}},[_c('b-card-body',[_c('div',{staticClass:"font-weight-bold myRow",staticStyle:{"height":"30px","background-color":"#f8f8f8","border-bottom":"1px solid #e8eaed","text-transform":"capitalize"}},[_c('font-awesome-icon',{staticClass:"mr-75 font-medium-1",attrs:{"icon":['fal', 'exchange']}}),_vm._v(" "+_vm._s(_vm.$route.path .split("/reports/")[1] .replaceAll("-", " "))+" ")],1),_c('div',{staticClass:"d-flex justify-content-between align-items-center myRow",staticStyle:{"height":"40px","border-bottom":"1px solid #e8eaed"}},[_c('div',{staticClass:"d-flex justify-content-between flex-grow-1 align-items-center",staticStyle:{"margin-right":"28px"}},[_c('span',[_vm._v("From Date:")]),_c('v-date-picker',{staticClass:"inline-block h-full",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var togglePopover = ref.togglePopover;
return [_c('div',{staticClass:"flex items-center position-relative"},[_c('font-awesome-icon',{staticClass:"mr-75 font-medium-1 cursor-pointer",staticStyle:{"position":"absolute","right":"-2","top":"6"},attrs:{"icon":['fas', 'calendar-alt']},on:{"click":function($event){return togglePopover()}}}),_c('input',{staticClass:"bg-white text-gray-700 py-1 appearance-none border rounded-r focus:outline-none focus:border-blue-500",staticStyle:{"max-height":"26px","max-width":"169px","padding-left":"10px"},attrs:{"readonly":""},domProps:{"value":inputValue}})],1)]}}]),model:{value:(_vm.from_date),callback:function ($$v) {_vm.from_date=$$v},expression:"from_date"}})],1),_c('div',{staticClass:"d-flex justify-content-between flex-grow-1 align-items-center"},[_c('span',[_vm._v("To Date:")]),_c('v-date-picker',{staticClass:"inline-block h-full",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var togglePopover = ref.togglePopover;
return [_c('div',{staticClass:"flex items-center position-relative"},[_c('font-awesome-icon',{staticClass:"mr-75 font-medium-1 cursor-pointer",staticStyle:{"position":"absolute","right":"-2","top":"6"},attrs:{"icon":['fas', 'calendar-alt']},on:{"click":function($event){return togglePopover()}}}),_c('input',{staticClass:"bg-white text-gray-700 py-1 appearance-none border rounded-r focus:outline-none focus:border-blue-500",staticStyle:{"max-height":"26px","max-width":"169px","padding-left":"10px"},attrs:{"readonly":""},domProps:{"value":inputValue}})],1)]}}]),model:{value:(_vm.to_date),callback:function ($$v) {_vm.to_date=$$v},expression:"to_date"}})],1)]),_c('div',{staticClass:"d-flex justify-content-between align-items-center myRow"},[(
            _vm.$route.path.split('/reports/')[1] ==
              'detailed-trading-per-acc' && _vm.accounts
          )?_c('div',{staticClass:"d-flex justify-content-between align-items-center w-75"},[_c('span',[_vm._v("Account:")]),(!_vm.waitingDownload)?_c('v-select',{attrs:{"label":"label","options":_vm.accounts,"reduce":function (acc) { return acc.account_id; }},model:{value:(_vm.selectedIbAccount),callback:function ($$v) {_vm.selectedIbAccount=$$v},expression:"selectedIbAccount"}}):_c('b-spinner',{attrs:{"variant":"primary","small":""}})],1):_vm._e()]),_c('div',{staticClass:"text-right",staticStyle:{"height":"64px"}},[_c('b-button',{staticStyle:{"margin-top":"12px","margin-right":"20px","width":"110px","height":"38px"},attrs:{"variant":"gradient-primary"},on:{"click":_vm.downloadSubmit}},[(!_vm.waitingDownload)?_c('span',[_vm._v(" Download ")]):_c('b-spinner',{attrs:{"variant":"primary","small":""}})],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }