<template>
  <div>
    <b-card no-body style="max-width: 600px">
      <b-card-body>
        <div
          class="font-weight-bold myRow"
          style="
            height: 30px;
            background-color: #f8f8f8;
            border-bottom: 1px solid #e8eaed;
            text-transform: capitalize;
          ">
          <font-awesome-icon
            :icon="['fal', 'exchange']"
            class="mr-75 font-medium-1" />
          {{
            $route.path
              .split("/reports/")[1]
              .replaceAll("-", " ")
          }}
        </div>
        <div
          class="d-flex justify-content-between align-items-center myRow"
          style="
            height: 40px;
            border-bottom: 1px solid #e8eaed;
          ">
          <div
            class="d-flex justify-content-between flex-grow-1 align-items-center"
            style="margin-right: 28px">
            <span>From Date:</span>
            <v-date-picker
              class="inline-block h-full"
              v-model="from_date">
              <template
                v-slot="{ inputValue, togglePopover }">
                <div
                  class="flex items-center position-relative">
                  <font-awesome-icon
                    :icon="['fas', 'calendar-alt']"
                    class="mr-75 font-medium-1 cursor-pointer"
                    @click="togglePopover()"
                    style="
                      position: absolute;
                      right: -2;
                      top: 6;
                    " />
                  <input
                    :value="inputValue"
                    class="bg-white text-gray-700 py-1 appearance-none border rounded-r focus:outline-none focus:border-blue-500"
                    readonly
                    style="
                      max-height: 26px;
                      max-width: 169px;
                      padding-left: 10px;
                    " />
                </div>
              </template>
            </v-date-picker>
          </div>
          <div
            class="d-flex justify-content-between flex-grow-1 align-items-center">
            <span>To Date:</span>
            <v-date-picker
              class="inline-block h-full"
              v-model="to_date">
              <template
                v-slot="{ inputValue, togglePopover }">
                <div
                  class="flex items-center position-relative">
                  <font-awesome-icon
                    :icon="['fas', 'calendar-alt']"
                    class="mr-75 font-medium-1 cursor-pointer"
                    @click="togglePopover()"
                    style="
                      position: absolute;
                      right: -2;
                      top: 6;
                    " />
                  <input
                    :value="inputValue"
                    class="bg-white text-gray-700 py-1 appearance-none border rounded-r focus:outline-none focus:border-blue-500"
                    readonly
                    style="
                      max-height: 26px;
                      max-width: 169px;
                      padding-left: 10px;
                    " />
                </div>
              </template>
            </v-date-picker>
          </div>
        </div>
        <div
          class="d-flex justify-content-between align-items-center myRow">
          <div
            class="d-flex justify-content-between align-items-center w-75"
            v-if="
              $route.path.split('/reports/')[1] ==
                'detailed-trading-per-acc' && accounts
            ">
            <span>Account:</span>
            <v-select
              v-model="selectedIbAccount"
              label="label"
              :options="accounts"
              :reduce="(acc) => acc.account_id"
              v-if="!waitingDownload" />
            <b-spinner
              v-else
              variant="primary"
              small></b-spinner>
          </div>
        </div>
        <div class="text-right" style="height: 64px">
          <b-button
            variant="gradient-primary"
            style="
              margin-top: 12px;
              margin-right: 20px;
              width: 110px;
              height: 38px;
            "
            @click="downloadSubmit"
            ><span v-if="!waitingDownload"> Download </span>
            <b-spinner
              v-else
              variant="primary"
              small></b-spinner>
          </b-button>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import axios from "@axios";
import vSelect from "vue-select";
import { mapGetters } from "vuex";
export default {
  components: {
    vSelect,
  },
  data() {
    return {
      waitingDownload: true,
      from_date: new Date(),
      to_date: new Date(),
      selectedIbAccount: null,
      accounts: null,
    };
  },
  methods: {
    async getAccounts() {
      this.waitingDownload = true;

      const res = await axios.get(
        `${localStorage.getItem("VUE_APP_API_URL")}/ibs/${
          this.ib_id
        }/get_accounts`
      );
      this.accounts = res.data
        .filter(
          (acc) =>
            acc.mt_account && acc.account_type == "live"
        )
        .map((acc) => ({
          ...acc,
          label:
            acc.account_id + " " + acc.mt_account.email,
        }));
      this.waitingDownload = false;
    },
    async downloadSubmit() {
      const res = await axios.post(
        `${localStorage.getItem(
          "VUE_APP_API_URL"
        )}/reports/export_xlsx_ib_${this.$route.path
          .split("/reports/")[1]
          .replaceAll("-", "_")}`,
        {
          from_date: this.from_date,
          to_date: this.to_date,
          filter: {
            selectedIb: "",
            selectedIbAccount: this.selectedIbAccount,
          },
        },
        { responseType: "blob" }
      );
      const blob = new Blob([res.data]);
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.setAttribute(
        "download",
        `${this.$route.path
          .split("/reports/")[1]
          .replaceAll("_", " ")
          .replace(/\w\S*/g, function (txt) {
            return (
              txt.charAt(0).toUpperCase() +
              txt.substr(1).toLowerCase()
            );
          })} Report.xls`
      );
      link.click();
      URL.revokeObjectURL(link.href);
    },
  },
  computed: {
    ...mapGetters({
      ib_id: "partner/currentIbId",
    }),
  },
  async mounted() {
    await this.getAccounts();
  },
  watch: {
    ib_id: {
      handler: async function (value) {
        if (value) {
          await this.getAccounts();
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.myRow {
  padding: 0 20px;
  display: flex;
  align-items: center;
}
.vs--single .vs__dropdown-toggle {
  padding: 0 !important;
  white-space: nowrap;
}
::v-deep .modal-dialog {
  // position: absolute;
  margin: auto !important;
}
::v-deep .modal-content {
  margin: auto !important;
}
.input {
  max-width: 386px;
}
label {
  font-size: 14px;
}

.v-select {
  width: 236px;
}
.table-active {
  background-color: transparent !important;
  color: green !important;
}
</style>
